import dashboard from "./dashboard";
import pages from "./pages";
import business from "./business";
import nonProfit from "./nonProfit";
import donation from "./donation";
import setting from "./setting";
import refund from "./refund";
import redemption from "./redemption";
import withdrawl from "./withdrawl";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    business,
    nonProfit,
    donation,
    redemption,
    withdrawl,
    refund,
    setting,
  ],
};

export default menuItems;
