import { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "firebase-config";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";

export default function StatusSelector({ rowData, status, collection }) {
  const [updateStatus, setUpdateStatus] = useState(status);
  const [] = useState();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setUpdateStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    const accountId = rowData?.id;
    if (collection === "UserDetails") {
      //Non Profit Account Status Update

      const docRef = doc(db, collection, accountId);
      try {
        const updateData = { AccountStatus: updateStatus };
        await updateDoc(docRef, updateData);

        toast.success("Account Status Updated");
        setTimeout(() => {
          navigate("/nonProfit");
        }, 4000);
      } catch (error) {
        toast.error("Error updating status:", error);
      }
    } else if (collection === "withdraw") {
      //Withdrawl Request Status Update

      const userId = rowData?.id;
      const docRef = doc(db, collection, userId);
      try {
        const docSnap = await getDoc(docRef);
        const docData = docSnap.data();
        const payStatusObj = docData?.withdrawals.filter(
          (withdraw) => withdraw?.id === rowData?.withdrawId
        );
        payStatusObj[0].paymentStatus = updateStatus;
        await updateDoc(docRef, { ["withdrawals"]: payStatusObj });

        //Redeem Items Status Update
      if(updateStatus === "Approved") {
        const updateItems = (userId) => {
          let data = {};

          if (userId) {
            let arr = [];
            payStatusObj.map((data) => {
              data.items.map((item) => {
                arr.push(item._id);
              });
            });
            data = {
              userId: userId,
              items: arr,
            };
          }
          return data;
        };
        const itemsUpdate = updateItems(userId);
        const reddemDocRef = doc(db, "Redemption", userId);
        try {
          const docSnap = await getDoc(reddemDocRef);
          const docData = docSnap.data();

          const { dataArray } = docData;
          itemsUpdate.items.map(async (item) => {
            const index = dataArray.findIndex((obj) => obj._id === item);

            dataArray[index] = {
              ...dataArray[index],
              status: `Redeem Withdrawl ${updateStatus}`,
            };
            await updateDoc(reddemDocRef, {
              ["dataArray"]: dataArray,
            });
          });
        } catch (error) {
          toast.error(error);
        }
      }
        toast.success("Withdrawl Status Updated");
        setTimeout(() => {
          navigate("/withdrawls");
        }, 4000);
      } catch (error) {
        toast.error("Error updating status:", error);
      }
    }
  };

  return (
    <Box sx={{ minWidth: 50, marginTop: "0.58rem", marginLeft: "0.58rem" }}>
      <ToastContainer />
      <FormControl>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          marginTop="1rem"
          sx={{ width: "100%", gap: "10px" }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={updateStatus}
            onChange={handleChange}
            placeholder="Pending"
            sx={{ width: "15vw", height: "5%", fontSize: "large" }}
          >
            <MenuItem color="grey" value="Pending">
              Pending
            </MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Declined">Declined</MenuItem>
          </Select>
          <Button
            variant="contained"
            type="submit"
            onClick={handleUpdateStatus}
            sx={{
              paddingTop: "0.4rem",
              minWidth: "7rem",
              height: "3.1rem",
              marginTop: "0.58rem",
              marginBottom: "0.58rem",
              marginRight: "0.5rem",
            }}
          >
            Update Status
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
}
