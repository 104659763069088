import React, { useState, useEffect } from 'react';
import AppContext from './AppContext';
const AppProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(() => {
        const userInfoData = localStorage.getItem('userInfo');
        return userInfoData ? JSON.parse(userInfoData) : null;
    });
    const [user, setUser] = useState(() => {
        const userData = localStorage.getItem('user');
        return userData ? JSON.parse(userData) : null;
    });
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        // Retrieve data from local storage on component mount
        const userInfo = localStorage.getItem('userInfo');
        const user = localStorage.getItem('user');
        if (userInfo) {
            setUserInfo(JSON.parse(userInfo));
        }
        if (user) {
            setUser(JSON.parse(user));
        }
    }, [counter]);

    return <AppContext.Provider value={{ userInfo, user, counter, setCounter }}>{children}</AppContext.Provider>;
};

export default AppProvider;
