// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const nonProfit = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'nonProfit',
            title: 'Non Profit',
            type: 'item',
            url: '/nonProfit',
            icon: VolunteerActivismOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default nonProfit;
