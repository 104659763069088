import { createStore, compose } from "redux";
import reducer from "./reducer";
import * as actionTypes from "./actions/tokenAction";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// ==============================|| REDUX - MAIN STORE ||============================== //
// compose enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, composeEnhancers());

const persistor = persistStore(store);
const setToken = (token) => ({
  type: actionTypes.SET_TOKEN,
  token,
});

const clearToken = () => ({
  type: actionTypes.CLEAR_TOKEN,
});
const token = localStorage.getItem("token");
setToken();
export { store, persistor, setToken, clearToken };
