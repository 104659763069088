import React from 'react';
import { useLocation } from 'react-router';
import RedemptionOrderDetailsTable from 'components/Table/RedemptionOrderDetailsTable';
import StatusSelector from 'components/StatusSelector';

const WithdrawlDetailPage = () => {
  const location = useLocation();
  const rowData = location.state;

  const orderDetailsTableheadCells = [
    {
      name: 'SrNo',
      label: '#',
    },
    {
      name: 'ItemName',
      label: 'Item Name',
    },
    {
      name: 'Quantity',
      label: 'Quantity',
    },
    {
      name: 'Price',
      label: 'Price',
    },
  ];

  return (
    <div>
      <div
        style={{
          background: 'white',
          borderRadius: '10px',
          padding: '2rem 2rem 1rem 2rem',
        }}
      >
        <h2 style={{ color: `#006838`, marginBottom: '1.5rem' }}>
          Business Details
        </h2>
        <div style={{ display: 'flex' }}>
          <div
            style={{ width: '20%', fontWeight: 'bold', paddingLeft: '0.5rem' }}
          >
            <p>Business Name</p>
            <p>Time</p>
            {/* <p>Routing Number</p> */}
          </div>
          <div style={{ width: '25%' }}>
            <p>{rowData.businessName}</p>
            <p>
              {rowData.createdAt !== null
                ? new Date(
                    rowData.createdAt.seconds * 1000 +
                      rowData.createdAt.nanoseconds / 1000000
                  ).toLocaleString()
                : ''}
            </p>
            {/* <p>{rowData.RoutingNumber}</p> */}
          </div>
          <div
            style={{ width: '20%', fontWeight: 'bold', paddingLeft: '2.5rem' }}
          >
            <p>Business Industry</p>
            <p>Amount Requested</p>
          </div>
          <div style={{ width: '25%', paddingLeft: '1.5rem' }}>
            <p>{rowData.businessType}</p>
            <p>${rowData.withdrawAmount}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '58%',
          height: '60%',
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '1.5rem',
          marginTop: '1rem',
        }}
      >
        <div>
          <h2
            style={{
              color: `#006838`,
              paddingTop: '1rem',
            }}
          >
            Withdrawl Request Approval
          </h2>
          <StatusSelector
            rowData={rowData}
            status={rowData.paymentStatus}
            collection='withdraw'
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingTop: '1rem',
          justifyContent: 'normal',
        }}
      >
        {/* <div
          style={{
            width: "60%",
          }}
        >
          <RedemptionOrderDetailsTable
            headCells={orderDetailsTableheadCells}
            row={rowData.items}
            duePayment={rowData.DuePayment}
            redeemAmount={rowData.RedeemAmount}
          />
        </div> */}
      </div>
    </div>
  );
};

export default WithdrawlDetailPage;
