// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const withdrawl = {
  id: "user-docs-roadmapredeem",
  type: "group",
  children: [
    {
      id: "withdrawl",
      title: "Withdrawls",
      type: "item",
      url: "/withdrawls",
      icon: AccountBalanceWalletOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default withdrawl;
