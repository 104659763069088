// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-rorn0c-MuiTableContainer-root::-webkit-scrollbar {
  /* display: none !important; */
}

.scrollable-div::-webkit-scrollbar {
  display: none;
}

@media (max-width: 899px) {
  .profile-picture {
    padding: 5%;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
}
@media (min-width: 900px) and (max-width: 1023px) {
  .profile-picture {
    padding: 5%;
    border-radius: 50%;
    width: 11rem;
    height: 11rem;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .profile-picture {
    padding: 5%;
    border-radius: 50%;
    width: 11rem;
    height: 11rem;
  }
}
@media (min-width: 1200px) {
  .profile-picture {
    padding: 5%;
    border-radius: 50%;
    height: 12rem;
    width: 12rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/styles/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;AACF;AACA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;AACF;AACA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;AACF;AACA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,YAAY;EACd;AACF","sourcesContent":[".css-rorn0c-MuiTableContainer-root::-webkit-scrollbar {\n  /* display: none !important; */\n}\n\n.scrollable-div::-webkit-scrollbar {\n  display: none;\n}\n\n@media (max-width: 899px) {\n  .profile-picture {\n    padding: 5%;\n    border-radius: 50%;\n    width: 10rem;\n    height: 10rem;\n  }\n}\n@media (min-width: 900px) and (max-width: 1023px) {\n  .profile-picture {\n    padding: 5%;\n    border-radius: 50%;\n    width: 11rem;\n    height: 11rem;\n  }\n}\n@media (min-width: 1024px) and (max-width: 1199px) {\n  .profile-picture {\n    padding: 5%;\n    border-radius: 50%;\n    width: 11rem;\n    height: 11rem;\n  }\n}\n@media (min-width: 1200px) {\n  .profile-picture {\n    padding: 5%;\n    border-radius: 50%;\n    height: 12rem;\n    width: 12rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
