import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { getToken } from "../utils/getToken";
import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import BusinessProfilePreview from "components/Table/ProfileForm";
import NonProfitProfilePreview from "components/Table/NonProfitProfileForm";
import RedemptionDetailPage from "components/Table/RedemptionDetail";
import WithdrawlDetailPage from "components/Table/WithdrawlDetails";
import BusinessQrGenerator from "components/Table/QrGenerator";
// User routing
const Business = Loadable(lazy(() => import("views/business")));

// Branch routing
const DonationPage = Loadable(lazy(() => import("views/donation")));
// Add User routing
const AddUser = Loadable(lazy(() => import("views/add-user")));

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);

// sample page routing
const NonProfit = Loadable(lazy(() => import("views/nonProfit")));

const WithdrawlPage = Loadable(lazy(() => import("views/withdrawal")));

// Notification page routing
const SettingPage = Loadable(lazy(() => import("views/setting")));
// get all order table

const RefundPage = Loadable(lazy(() => import("views/refund")));
// get all taxes
const RedemptionPage = Loadable(lazy(() => import("views/redemption")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "utils",
      children: [
        {
          path: "util-typography",
          element: <UtilsTypography />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-color",
          element: <UtilsColor />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-shadow",
          element: <UtilsShadow />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <UtilsTablerIcons />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "business",
      element: <Business />,
    },
    {
      path: "businessProfileForm",
      element: <BusinessProfilePreview />,
    },
    {
      path: "qrGenerator",
      children: [
        {
          path: ":id",
          element: <BusinessQrGenerator />,
        },
      ],
    },
    {
      path: "addUser",
      element: <AddUser />,
    },
    {
      path: "donation",
      element: <DonationPage />,
    },
    {
      path: "nonProfit",
      element: <NonProfit />,
    },
    {
      path: "nonProfitProfileForm",
      element: <NonProfitProfilePreview />,
    },
    {
      path: "setting",
      element: <SettingPage />,
    },
    {
      path: "refund",
      element: <RefundPage />,
    },
    {
      path: "withdrawls",
      element: <WithdrawlPage />,
    },
    {
      path: "redemption",
      element: <RedemptionPage />,
    },
    {
      path: "redemptionDetail",
      element: <RedemptionDetailPage />,
    },
    {
      path: "withdrawlDetail",
      element: <WithdrawlDetailPage />,
    },
  ],
};

export default MainRoutes;
