// project imports
import * as actionTypes from "../actions/tokenAction";

export const initialState = {
  token: null,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case actionTypes.CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default tokenReducer;
