import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./reducers/customizationReducer";
import tokenReducer from "./reducers/tokenReducer";
import UserReducer from "./reducers/UserReducer";
import CardNumbersReducer from "./reducers/cardNumbersReducer";
import redeemDataReducer from "./reducers/redeemDataReducer";
import donateDataReducer from "./reducers/donatedDataReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  token: tokenReducer,
  user: UserReducer,
  cardNumbers: CardNumbersReducer,
  redeemData: redeemDataReducer,
  donateData: donateDataReducer,
});

export default reducer;
