// material-ui
import { useTheme } from "@mui/material/styles";
import { MENU_OPEN, SET_MENU } from "../store/actions/actions";
import logo from "assets/images/logo1.png";
import { useDispatch } from "react-redux";
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleLogo = () => {
    let id = "logo";
    dispatch({ type: MENU_OPEN, id });
  };
  return (
    <img
      src={logo}
      alt="Berry"
      height="40"
      style={{ borderRadius: "10%" }}
      draggable={false}
      onClick={handleLogo}
    />
  );
};

export default Logo;
