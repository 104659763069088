export const initialState = {
  DonateData: null,
};

const donateDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DONATED_DATA":
      return {
        ...state,
        DonateData: action.payload,
      };
    default:
      return state;
  }
};

export default donateDataReducer;
