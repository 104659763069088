import React, { useRef } from "react";
import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { toast } from "react-toastify";

// QRCodeGenerator component to generate Qr Code

const QRCodeGenerator = ({ value }) => {
  return (
    <>
      <div style={{ marginLeft: "2rem" }}>
        <QRCode
          value={value}
          renderAs="canvas"
          size={250}
          level={"H"}
          includeMargin={true}
          scale={10}
          // ref={svgRef}
        />
      </div>
      <br />
    </>
  );
};

// Default component to create unique Business Qrcode utilizing QRCodeGenerator component

const BusinessQrGenerator = ({ row }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "3rem",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [open, setOpen] = useState(false);
  const donationURL = `${process.env.REACT_APP_DONATION_URL}/${row.id}`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const svgRef = useRef(null);

  const handleCopyURL = () => {
    navigator.clipboard
      .writeText(donationURL)
      .then(() => {
        toast.success("Url copied to clipboard");
      })
      .catch((err) => {
        toast.error("Error copying url: ", err);
      });
  };

  const handleQrDownload = () => {
    const svg = svgRef.current;
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);

      let downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "QRCode.svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <QrCode2Icon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ ...style, width: 450 }}
        >
          <h2 id="parent-modal-title">Business Name: {row.businessName}</h2>
          <div>
            <h2>Scan Here</h2>
          </div>
          <img
            src={row?.qrImage?.imageUrl}
            width={250}
            height={250}
            ref={svgRef}
          />
          <Button onClick={handleQrDownload}>Download QR</Button>
          <Button onClick={handleCopyURL}>Copy URL</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default BusinessQrGenerator;
