// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import CribOutlinedIcon from "@mui/icons-material/CribOutlined";
import ViewListIcon from "@mui/icons-material/ViewList";
import CurrencyExchangeSharpIcon from "@mui/icons-material/CurrencyExchangeSharp";
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const refund = {
  id: "user-docs-roadmaprefund",
  type: "group",
  children: [
    {
      id: "refund",
      title: "Refund",
      type: "item",
      url: "/refund",
      icon: CurrencyExchangeSharpIcon,
      breadcrumbs: false,
    },
  ],
};

export default refund;
