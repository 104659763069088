export const initialState = {
  NumberOfOrganizations: null,
};

const CardNumbersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NO_OF_ORGANIZATION":
      return {
        ...state,
        NumberOfOrganizations: action.payload,
      };
    default:
      return state;
  }
};

export default CardNumbersReducer;
