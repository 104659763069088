// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const business = {
    id: 'user-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'business',
            title: 'Businesses',
            type: 'item',
            url: '/business',
            icon: BusinessCenterSharpIcon,
            breadcrumbs: false
        }
    ]
};

export default business;
