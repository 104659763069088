import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import NonProfitActivityTable from 'components/Table/NonProfitActivityTable';
import NonProfitLimitForm from './NonProfitRedemptionLimitForm';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from 'firebase-config';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import StatusSelector from 'components/StatusSelector';

const NonProfitProfilePreview = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [rowData, setRowData] = useState(location.state);
  const [imgPath, setImgPath] = useState('');
  const [copyData, setCopyData] = React.useState(() => {
    return {
      organizationName: rowData?.organizationName,
      organizationEmail: rowData?.UserEmail,
      organizationPhoneNumber: rowData?.organizationPhoneNumber,
      organizationDetails: rowData?.organizationDetails,
      organizationLogo: rowData?.organizationLogo,
    };
  });

  const redemptionData = useSelector((state) => state.redeemData);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(
        storage,
        `${rowData?.id}/${file?.type}/${file?.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      const snapshot = await uploadTask;
      try {
        const downloadURL = await getDownloadURL(snapshot.ref);
        setImgPath(downloadURL);
      } catch {
        toast.error('URL error');
      }
    }
  };

  const updateProfilePicture = async (newImageUrl) => {
    const imgRef = doc(db, 'UserDetails', rowData.id);
    if (newImageUrl !== '') copyData.organizationLogo = newImageUrl;
    const imgData = {
      organizationLogo: newImageUrl,
    };
    try {
      const res = await updateDoc(imgRef, imgData);
      setCopyData((prevData) => ({
        ...prevData,
        organizationLogo: newImageUrl,
      }));
    } catch (error) {
      toast.error('Error updating profile picture:');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'UserDetails', rowData.id);
    try {
      const docSnap = await getDoc(docRef);
      const existingData = docSnap.data();
      if (imgPath !== '') updateProfilePicture(imgPath);
      const hasChanged =
        copyData.organizationName !== existingData.organizationName ||
        copyData.organizationLogo !== existingData.organizationLogo ||
        copyData.UserEmail !== existingData.UserEmail ||
        copyData.organizationDetails !== existingData.organizationDetails ||
        copyData.organizationPhoneNumber !==
          existingData.organizationPhoneNumber;

      if (hasChanged || imgPath !== '') {
        await updateDoc(docRef, copyData);
        toast.success('Profile Updated Successfully');
        setTimeout(() => {
          Navigate('/nonProfit');
        }, 2000);
      } else {
        toast.info('No changes detected. Profile not updated.');
      }
    } catch (error) {
      toast.error('Error updating profile details:', error);
    }
  };

  const nonProfitActivityTabledata = redemptionData?.RedeemData?.filter(
    (item) => item.id === rowData?.id
  );

  const nonProfitActivityTableheadCells = [
    {
      name: 'Transaction',
      label: '',
    },
    {
      name: 'Date',
      label: '',
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div
        style={{ background: `white`, borderRadius: '10px', padding: '2rem' }}
      >
        <h2 style={{ color: `#006838`, marginBottom: '3rem' }}>
          Profile Details
        </h2>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '95%' },
            }}
            noValidate
            autoComplete='off'
          >
            <div
              className='nonProfitProfile'
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '26.2%',
                }}
              >
                <Typography variant='h5'>Profile Picture</Typography>
                <div
                  className='Avatar'
                  style={{
                    width: '85%',
                    height: '18rem',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={rowData?.organizationLogo || imgPath}
                    className='profile-picture'
                  />
                  <Typography
                    variant='caption'
                    sx={{ textAlign: 'center', paddingLeft: '0.4rem' }}
                  >
                    Upload/Update Your Profile Image
                  </Typography>
                  <label
                    htmlFor='profile-picture-upload'
                    style={{
                      cursor: 'pointer',
                      paddingLeft: '0.11rem',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      name='organizationLogo'
                      type='file'
                      accept='image/*'
                      style={{
                        paddingLeft: '20%',
                        paddingRight: '1rem',
                        minWidth: '8rem',
                        display: 'none',
                      }}
                      id='profile-picture-upload'
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileInputChange}
                    />
                  </label>
                  <br />
                  <Button
                    onClick={handleButtonClick}
                    variant='contained'
                    sx={{
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      minWidth: '8rem',
                    }}
                  >
                    Upload Picture
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '70%',
                }}
              >
                <Typography variant='h5'>Edit Profile</Typography>
                <br />
                <br />
                <TextField
                  id='outlined-textarea'
                  label='Non Profit Name'
                  placeholder='Non Profit Name'
                  name='organizationName'
                  value={copyData.organizationName}
                  onChange={(e) => {
                    setCopyData((prevData) => {
                      const nameValue = e.target.value.replace(
                        /[^a-zA-Z ]/g,
                        ''
                      );
                      const truncatedValue = nameValue.slice(0, 50);

                      return {
                        ...prevData,
                        [e.target.name]: truncatedValue,
                      };
                    });
                  }}
                  multiline
                />
                <TextField
                  id='outlined-textarea'
                  label='Address'
                  placeholder='Address'
                  InputProps={{
                    readOnly: true,
                  }}
                  onClick={() => {
                    toast.error('This is a read only field');
                  }}
                  value={`${rowData.organizationAddress1 || ''}, ${
                    rowData.organizationCity || ''
                  }, ${rowData.organizationState}`}
                  multiline
                />
                <div style={{ display: 'flex', width: '97%' }}>
                  <TextField
                    id='outlined-textarea'
                    label='Email'
                    placeholder='Email...'
                    value={rowData.UserEmail}
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={() => {
                      toast.error('This is a read only field');
                    }}
                    multiline
                  />
                  <TextField
                    id='outlined-textarea'
                    name='organizationPhoneNumber'
                    label='Phone'
                    placeholder='Phone No...'
                    value={copyData.organizationPhoneNumber}
                    onChange={(e) => {
                      setCopyData((prevData) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9+() ]/g,
                          ''
                        );
                        const truncatedValue = numericValue.slice(0, 20);

                        return {
                          ...prevData,
                          [e.target.name]: truncatedValue,
                        };
                      });
                    }}
                    multiline
                  />
                </div>
                <TextField
                  id='outlined-textarea'
                  name='organizationDetails'
                  label='Non Profit Details'
                  placeholder='Non Profit Details'
                  value={copyData.organizationDetails}
                  onChange={(e) => {
                    setCopyData((prevData) => {
                      const truncatedValue = e.target.value.slice(0, 400);

                      return {
                        ...prevData,
                        [e.target.name]: truncatedValue,
                      };
                    });
                  }}
                  multiline
                />
              </div>
            </div>
          </Box>
          <div
            style={{
              paddingTop: '2rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              type='submit'
              sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
      <div
        style={{
          display: 'flex',
          paddingTop: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '58%',
            height: '60%',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '1.5rem',
          }}
        >
          <NonProfitLimitForm rowData={rowData} />
          <div>
            <h2
              style={{
                color: `#006838`,
              }}
            >
              Profile Status
            </h2>
            <StatusSelector
              rowData={rowData}
              status={rowData.AccountStatus}
              collection='UserDetails'
            />
          </div>
        </div>
        <div style={{ width: '40%' }}>
          <NonProfitActivityTable
            headCells={nonProfitActivityTableheadCells}
            row={nonProfitActivityTabledata}
          />
        </div>
      </div>
    </div>
  );
};

export default NonProfitProfilePreview;
