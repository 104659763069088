// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import CribOutlinedIcon from "@mui/icons-material/CribOutlined";
import RedeemIcon from "@mui/icons-material/Redeem";
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const redemption = {
  id: "user-docs-roadmapredeem",
  type: "group",
  children: [
    {
      id: "redemption",
      title: "Redemptions",
      type: "item",
      url: "/redemption",
      icon: RedeemIcon,
      breadcrumbs: false,
    },
  ],
};

export default redemption;
