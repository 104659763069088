import { createRoot } from "react-dom/client";
import { HttpLink } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
// project imports
import * as serviceWorker from "serviceWorker";
import App from "App";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import AppProvider from "context/AppProvider";
// style + assets
import "assets/scss/style.scss";
import config from "./config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <AppProvider>
    <Provider store={store}>
      <ToastContainer />
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={config.basename}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </AppProvider>
);

serviceWorker.unregister();
