import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthRegister3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);
const DonationPayment = Loadable(lazy(() => import("views/donation-payment")));

const SquarePayment = Loadable(
  lazy(() => import("views/donation-payment/header/square-payment"))
);
const TermsOfService = Loadable(
  lazy(() => import("views/donation-payment/terms-of-services"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("views/donation-payment/privacy-policy"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin3 />,
    },
    {
      path: "/login",
      element: <AuthLogin3 />,
    },
    {
      path: "/register",
      element: <AuthRegister3 />,
    },
    {
      path: "/donationsPayment/:id",
      element: <DonationPayment />,
    },
    {
      path: "/squarePayment",
      element: <SquarePayment />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfService />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
  ],
};

export default AuthenticationRoutes;
