// import { SET_USER_NAME } from "store/actions/userAction";
export const initialState = {
  AdminData: {},
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ADMIN_DATA":
      return {
        ...state,
        AdminData: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
