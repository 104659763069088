import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'firebase-config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
// import "../Table/styles/style.css";

const NonProfitLimitForm = ({ rowData }) => {
  const [inputValue, setInputValue] = useState();
  const [redemptionLimit, setredemptionLimit] = useState({
    currentValue: 200,
  });
  const navigate = useNavigate();
  const accountId = rowData?.id;
  const docRef = doc(db, 'UserDetails', accountId);

  return (
    <div>
      <div style={{}}>
        <h2
          style={{
            color: `#006838`,
          }}
        >
          Redemption Limit
        </h2>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '90%' },
          }}
          noValidate
          autoComplete='off'
        >
          <div
            style={{
              width: '12rem',
            }}
          >
            <Typography
              variant='h4'
              sx={{
                paddingBottom: '0.8rem',
                paddingLeft: '1rem',
                paddingTop: '1rem',
                borderRadius: '10px',
                color: 'black',
                border: '0.2px solid #c3ccc8',
                backgroundColor: '#f8fafc',
                marginLeft: '0.6rem',
              }}
            >
              Current Limit : ${redemptionLimit.currentValue}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '0.75rem',
            }}
          >
            <TextField
              id='outlined-textarea'
              label='New Limit'
              placeholder='Enter New Limit'
              sx={{ maxWidth: '15vw' }}
              type='number'
              multiline
              value={inputValue}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, '');
                const truncatedValue = numericValue.slice(0, 10);
                setInputValue(truncatedValue);
              }}
            />
            <Button
              variant='contained'
              type='submit'
              sx={{
                paddingTop: '0.4rem',
                minWidth: '7rem',
                height: '3.1rem',
                marginTop: '0.58rem',
                marginBottom: '0.58rem',
              }}
              onClick={async () => {
                setredemptionLimit({
                  currentValue: inputValue,
                });
                try {
                  const updateData = {
                    AccountLimit: {
                      Amount: inputValue,
                    },
                  };
                  await updateDoc(docRef, updateData);

                  toast.success('Redeem Limit Updated');
                  setTimeout(() => {
                    navigate('/nonProfit');
                  }, 4000);
                } catch (error) {
                  toast.error('Error updating limit:', error);
                }
                setInputValue('');
              }}
            >
              Update Limit
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default NonProfitLimitForm;
