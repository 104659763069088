import React from 'react';
import { useLocation } from 'react-router';
import { Button, Typography } from '@mui/material';
import RedemptionOrderDetailsTable from 'components/Table/RedemptionOrderDetailsTable';
import { useEffect } from 'react';

const RedemptionDetailPage = () => {
  const location = useLocation();
  const rowData = location.state;

  const orderDetailsTableheadCells = [
    {
      name: 'SrNo',
      label: '#',
    },
    {
      name: 'ItemName',
      label: 'Item Name',
    },
    {
      name: 'Quantity',
      label: 'Quantity',
    },
    {
      name: 'Price',
      label: 'Price',
    },
  ];

  return (
    <div>
      <div
        style={{
          background: 'white',
          borderRadius: '10px',
          padding: '2rem 2rem 1rem 2rem',
        }}
      >
        <h2 style={{ color: `#006838`, marginBottom: '1.5rem' }}>
          Redemption Details
        </h2>
        <div style={{ display: 'flex' }}>
          <div
            style={{ width: '20%', fontWeight: 'bold', paddingLeft: '0.5rem' }}
          >
            <p>Non Profit Name</p>
            <p>Time</p>
            <p>To be picked-up on</p>
          </div>
          <div style={{ width: '25%' }}>
            <p>{rowData.organizationName}</p>
            <p>
              {rowData.createdAt !== null
                ? new Date(
                    rowData.createdAt.seconds * 1000 +
                      rowData.createdAt.nanoseconds / 1000000
                  ).toLocaleString()
                : ''}
            </p>
            <p>{rowData?.pickUpTime || ''}</p>
          </div>
          <div
            style={{ width: '20%', fontWeight: 'bold', paddingLeft: '2.5rem' }}
          >
            <p>Business Name</p>
            <p>Business Industry</p>
          </div>
          <div style={{ width: '25%', paddingLeft: '1.5rem' }}>
            <p>{rowData.businessName}</p>
            <p>{rowData.businessType}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingTop: '1rem',
          justifyContent: 'normal',
        }}
      >
        <div
          style={{
            width: '60%',
          }}
        >
          <RedemptionOrderDetailsTable
            headCells={orderDetailsTableheadCells}
            row={rowData.items}
            duePayment={rowData.DuePayment}
            redeemAmount={rowData.RedeemAmount}
          />
        </div>
        <div
          className='scrollable-div'
          style={{
            width: '40%',
            backgroundColor: 'white',
            borderRadius: '1rem',
            maxHeight: '22rem',
            overflow: 'scroll',
          }}
        >
          <h3
            style={{
              color: '#006838',
              padding: '0.8rem 0.1rem 0.5rem 1rem',
            }}
          >
            Non Profit Details
          </h3>
          <div
            style={{
              display: 'flex',
              borderBottom: '0.5px solid #d9e0dd',
              borderTop: '0.5px solid #d9e0dd',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 'bold',
                fontsize: '2rem',
              }}
              width={'30%'}
            >
              Name
            </Typography>
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontsize: '2rem',
              }}
            >
              {rowData.organizationName}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: '0.5px solid #d9e0dd',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 'bold',
                fontsize: '2rem',
              }}
              width={'30%'}
            >
              Address
            </Typography>
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontsize: '2rem',
              }}
            >
              {`${rowData.organizationAddress1 || ''}, ${
                rowData.organizationCity || ''
              }, ${rowData.organizationState}`}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: '0.5px solid #d9e0dd',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 'bold',
                fontsize: '2rem',
              }}
              width={'30%'}
            >
              Email
            </Typography>
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontsize: '2rem',
              }}
            >
              {rowData.UserEmail}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: '0.5px solid #d9e0dd',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 'bold',
                fontsize: '2rem',
              }}
              width={'30%'}
            >
              Phone
            </Typography>
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontsize: '2rem',
              }}
            >
              {rowData.organizationPhoneNumber}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: '0.5px solid #d9e0dd',
            }}
          >
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 'bold',
                fontsize: '2rem',
              }}
              width={'30%'}
            >
              Redemption Limit
            </Typography>
            <Typography
              sx={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontsize: '2rem',
              }}
            >
              $200
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedemptionDetailPage;
