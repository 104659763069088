import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/storage";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyA4lrOkp9fkdnV8tB5wqL4BnzhPeGBCehI",
  authDomain: "rosasapppro.firebaseapp.com",
  projectId: "rosasapppro",
  storageBucket: "rosasapppro.appspot.com",
  messagingSenderId: "971817509766",
  appId: "1:971817509766:android:b16c0750fd9eabbbd44207",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
