import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, TextareaAutosize, Typography } from "@mui/material";
import ItemTable from "components/Table/ItemTable";
import BusinessActivityTable from "components/Table/BusinessActivityTable";
import "../Table/styles/style.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "firebase-config";
import { ToastContainer, toast } from "react-toastify";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const BusinessProfilePreview = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [activity, setAcitivity] = useState([]);
  const Navigate = useNavigate();
  const location = useLocation();
  const [rowData, setRowData] = useState(location.state);
  const [imgPath, setImgPath] = useState("");
  const fileInputRef = useRef(null);
  const [copyData, setCopyData] = React.useState(() => {
    return {
      businessName: rowData?.businessName,
      businessEmail: rowData?.UserEmail,
      businessType: rowData?.businessType,
      businessPhoneNumber: rowData?.businessPhoneNumber,
      businessDetails: rowData?.businessDetails,
      bussinessLogo: rowData?.bussinessLogo,
    };
  });

  const menu = collection(db, "MenuItems");

  const fetchData = async () => {
    try {
      const result = await getDocs(menu);
      const tableData = result.docs.map((item) => ({
        id: item.id,
        ...item.data(),
      }));
      setMenuItems(tableData);
    } catch (error) {
      toast.error("error to fetch data");
    }
  };

  let itemTableData = menuItems.filter((item) => item.id === rowData?.id);

  const itemTableheadCells = [
    {
      name: "SrNo",
      label: "#",
    },
    {
      name: "ItemName",
      label: "Item Name",
    },
    {
      name: "Price",
      label: "Price",
    },
    // {
    //   name: "Action",
    //   label: "Action",
    // },
  ];

  const donations = collection(db, "Donations");

  const fetchDonationData = async () => {
    try {
      const result = await getDocs(donations);
      const tableData = result.docs.map((item) => ({
        id: item.id,
        ...item.data(),
      }));
      setAcitivity(tableData);
    } catch (error) {
      toast.error("error to fetch data>>>");
    }
  };

  useEffect(() => {
    fetchData();
    fetchDonationData();
  }, []);

  let businessActivityTableData = activity.filter(
    (item) => item.id === rowData?.id
  );

  const businessActivityTableheadCells = [
    {
      name: "Transaction",
      label: "",
    },
    {
      name: "Date",
      label: "",
    },
  ];

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(
        storage,
        `${rowData?.id}/${file?.type}/${file?.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      const snapshot = await uploadTask;
      try {
        const downloadURL = await getDownloadURL(snapshot.ref);
        setImgPath(downloadURL);
      } catch {
        toast.error("URL error");
      }
    }
  };

  const updateProfilePicture = async (newImageUrl) => {
    const imgRef = doc(db, "UserDetails", rowData.id);
    if (newImageUrl !== "") copyData.bussinessLogo = newImageUrl;
    const imgData = {
      bussinessLogo: newImageUrl,
    };
    try {
      const res = await updateDoc(imgRef, imgData);
      setCopyData((prevData) => ({
        ...prevData,
        bussinessLogo: newImageUrl,
      }));
    } catch (error) {
      toast.error("Error updating profile picture:");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "UserDetails", rowData.id);
    try {
      const docSnap = await getDoc(docRef);
      const existingData = docSnap.data();
      if (imgPath !== "") updateProfilePicture(imgPath);
      const hasChanged =
        copyData.bussinessLogo !== existingData.bussinessLogo ||
        copyData.businessName !== existingData.businessName ||
        copyData.businessEmail !== existingData.UserEmail ||
        copyData.businessDetails !== existingData.businessDetails ||
        copyData.businessPhoneNumber !== existingData.businessPhoneNumber ||
        copyData.businessType !== existingData.businessType;

      if (hasChanged || imgPath !== "") {
        await updateDoc(docRef, copyData);
        toast.success("Submitted Successfully");
        setTimeout(() => {
          Navigate("/business");
        }, 2000);
      } else {
        toast.info("No changes detected. Form Submitted Successfully");
        setTimeout(() => {
          Navigate("/business");
        }, 2000);
      }
    } catch (error) {
      toast.error("Error updating profile details:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        style={{ background: `white`, borderRadius: "10px", padding: "2rem" }}
      >
        <h2 style={{ color: `#006838`, marginBottom: "3rem" }}>
          Profile Details
        </h2>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "95%" },
            }}
            noValidate
            autoComplete="off"
          >
            <div
              className="businessProfile"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "26.2%",
                }}
              >
                <Typography variant="h5">Profile Picture</Typography>
                <div
                  className="Avatar"
                  style={{
                    width: "85%",
                    height: "20rem",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={imgPath || rowData?.bussinessLogo}
                    className="profile-picture"
                  />
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "center", paddingLeft: "0.4rem" }}
                  >
                    Upload/Update Your Profile Image
                  </Typography>
                  <label
                    htmlFor="profile-picture-upload"
                    style={{
                      cursor: "pointer",
                      paddingLeft: "0.11rem",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      name="bussinessLogo"
                      type="file"
                      accept="image/*"
                      style={{
                        paddingLeft: "20%",
                        paddingRight: "1rem",
                        minWidth: "8rem",
                        display: "none",
                      }}
                      id="profile-picture-upload"
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileInputChange}
                    />
                  </label>
                  <br />
                  <Button
                    onClick={handleButtonClick}
                    variant="contained"
                    sx={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      minWidth: "8rem",
                    }}
                  >
                    Upload Picture
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "73%",
                }}
              >
                <Typography variant="h5">Edit Profile</Typography> <br />
                <br />
                <TextField
                  id="outlined-textarea"
                  name="businessName"
                  label="Business Name"
                  placeholder="Business Name"
                  value={copyData.businessName}
                  onChange={(e) => {
                    setCopyData((prevData) => {
                      const nameValue = e.target.value.replace(
                        /[^a-zA-Z ]/g,
                        ""
                      );
                      const truncatedValue = nameValue.slice(0, 50);

                      return {
                        ...prevData,
                        [e.target.name]: truncatedValue,
                      };
                    });
                  }}
                  multiline
                />
                <TextField
                  id="outlined-textarea"
                  name="businessType"
                  label="Industry"
                  placeholder="Industry"
                  value={copyData.businessType}
                  onChange={(e) => {
                    setCopyData((prevData) => {
                      const nameValue = e.target.value.replace(
                        /[^a-zA-Z ]/g,
                        ""
                      );
                      const truncatedValue = nameValue.slice(0, 50);

                      return {
                        ...prevData,
                        [e.target.name]: truncatedValue,
                      };
                    });
                  }}
                  multiline
                />
                <TextField
                  id="outlined-textarea"
                  name="businessAddress"
                  label="Address"
                  placeholder="Address"
                  onClick={() => {
                    toast.error("This is a read only field");
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={`${rowData?.businessAddress || ""}, ${
                    rowData?.businessCity || ""
                  }, ${rowData?.businessState || ""}`}
                  multiline
                />
                <div style={{ display: "flex", width: "97%" }}>
                  <TextField
                    id="outlined-textarea"
                    name="businessEmail"
                    label="Email"
                    placeholder="Email..."
                    value={copyData.businessEmail}
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={() => {
                      toast.error("This is a read only field");
                    }}
                    onChange={(e) => {
                      setCopyData((prevData) => {
                        return { ...prevData, [e.target.name]: e.target.value };
                      });
                    }}
                    multiline
                  />
                  <TextField
                    id="outlined-textarea"
                    name="businessPhoneNumber"
                    label="Phone"
                    placeholder="Phone No..."
                    value={copyData.businessPhoneNumber}
                    onChange={(e) => {
                      setCopyData((prevData) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9+() ]/g,
                          ""
                        );
                        const truncatedValue = numericValue.slice(0, 20);

                        return {
                          ...prevData,
                          [e.target.name]: truncatedValue,
                        };
                      });
                    }}
                    multiline
                  />
                </div>
                <TextField
                  id="outlined-textarea"
                  name="businessDetails"
                  label="Business Details"
                  placeholder="Business Details"
                  value={copyData.businessDetails}
                  onChange={(e) => {
                    setCopyData((prevData) => {
                      const truncatedValue = e.target.value.slice(0, 400);

                      return {
                        ...prevData,
                        [e.target.name]: truncatedValue,
                      };
                    });
                  }}
                  multiline
                  sx={{ height: "120px" }}
                />
              </div>
            </div>
          </Box>
          <div
            style={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              // onClick={handleSubmit}
              variant="contained"
              type="submit"
              sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "1rem",
          justifyContent: "normal",
        }}
      >
        <div style={{ width: "60%", paddingRight: "0.1rem" }}>
          <ItemTable headCells={itemTableheadCells} row={itemTableData} />
        </div>
        <div style={{ width: "40%" }}>
          <BusinessActivityTable
            headCells={businessActivityTableheadCells}
            row={businessActivityTableData}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessProfilePreview;
