import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';
import auth from './utils/auth';
// Define the error link with the onError callback
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        console.log('inside the graphql error ', graphQLErrors);
        // Check for specific error codes, such as 401 (unauthorized)
        for (const error of graphQLErrors) {
            if (error.message === 'Unauthorized access. Please Login First' || error.message === 'Login First') {
                console.log('sub inside the graphql error ', graphQLErrors);
                // Clear the cache
                client.clearStore();
                localStorage.clear();
                // Redirect the user to the login page
                // Replace the URL with your desired login page
                window.location.href = '/login';
            }
        }
    }

    if (networkError) {
        // Handle network errors here if needed
        console.log('Network error:', networkError);
    }
});

// Create an httpLink
const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URL}` // Replace with your GraphQL endpoint URL
});
const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

const authLink = setContext((_, { headers }) => {
    const token = auth.getToken() || null;
    return token
        ? {
              headers: {
                  ...headers,
                  authorization: token ? `Bearer ${token.accessToken}` : ''
              }
          }
        : null;
});
export const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward ? forward(operation) : null;
});

// Create an Apollo Client instance
const client = new ApolloClient({
    link: ApolloLink.from([omitTypenameLink, errorLink, authLink, httpLink]), // Assuming you have an HttpLink defined as `httpLink`
    cache: new InMemoryCache()
});

export default client;
