// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import CribOutlinedIcon from "@mui/icons-material/CribOutlined";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import LocalMallIcon from "@mui/icons-material/LocalMall";

// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const setting = {
  id: "user-docs-roadmapsetting",
  type: "group",
  children: [
    {
      id: "setting",
      title: "Settings",
      type: "item",
      url: "/setting",
      icon: SettingsSharpIcon,
      breadcrumbs: false,
    },
  ],
};

export default setting;
