export const initialState = {
  RedeemData: null,
};

const redeemDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REDEEMED_DATA":
      return {
        ...state,
        RedeemData: action.payload,
      };
    default:
      return state;
  }
};

export default redeemDataReducer;
